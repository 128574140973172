export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // Container
    '.container': {
      backgroundColor: 'background',
      padding: ['', '', '', '0rem 1rem'],
      display: 'flex',
      justifyContent: 'center',
      '.smallNavMenu': {
        // backgroundColor: '#ffffffba',s
        padding: '0.5rem',
        '> div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          a: {
            color: 'dark'
            // textTransform: 'uppercase'
          }
        },
        '.order': {
          a: {
            padding: '0.5rem 1rem',
            color: 'white',
            backgroundColor: 'primary',
            borderRadius: '50px',
            fontWeight: 'bold'
          }
        }
      },
      color: 'black'
    },

    '.containerScrolled': {
      backgroundColor: 'background',
      color: 'text',
      padding: '0rem',
      boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 15%)',
      borderBottom: 'none',
      '.smallNavMenu': {
        '> div': {
          borderColor: 'black',
          a: {
            color: 'text',
            fontWeight: '400',
            textTransform: 'uppercase'
          }
        },
        '.order': {
          a: {
            padding: '0.5rem 1rem',
            color: 'white',
            backgroundColor: 'primary',
            borderRadius: '50px',
            fontWeight: 'bold'
          }
        }
      }
    },

    // hamburger
    '.hamburger': {
      borderRadius: '10px',
      background: 'none',
      border: 'none',
      padding: '0.75rem',
      '> div': {
        backgroundColor: 'secondary',
        height: '2px'
      },
      'div:nth-of-type(2)': {
        width: '70%'
      }
    },
    'li.navItem': {
      a: {
        color: 'dark',
        fontFamily: 'body',
        // fontSize: ['1.7rem', '1.7rem', '1.7rem', '1.7rem', '1.7rem'],
        fontWeight: 'bold',
        textTransform: 'capitalize'
      }
    },

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      img: {
        filter: 'unset',
        maxHeight: '100px'
      }
    },
    '.logoScrolled': {
      img: {
        // filter: 'brightness(0) invert(1)',
        maxHeight: '60px'
      }
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '3rem 0rem',
      img: {
        maxHeight: ['80px', '100px'],
        filter: 'unset'
      }
    },

    '.navMenuOpen': {
      color: '#fceed7',
      backgroundColor: '#c7b897e3',
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'none'
      }
    }
  },

  footer: {
    backgroundColor: '#2a3c4b',
    position: ['', '', 'fixed'],
    bottom: '0rem',
    zIndex: '977',
    width: '100%',
    '.logo, .image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.contactDetails-container': {
      display: 'none'
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 3.5rem', '', '0rem 3rem 0rem'],
    '.multiButtonContainer': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0rem',
      padding: '0.5rem 0.5rem 0.5rem 0rem',
      a: {
        color: 'white',
        fontSize: '0.9rem',
        margin: '0rem',
        padding: '0.5rem 0.5rem 0.5rem 0rem'
      }
    },
    '.gonationLogo': {
      width: 'fit-content',
      padding: '0.25rem 1rem 0.25rem 0rem'
    },

    '.socialIconsContainer': {
      padding: '0.25rem 1rem 0.25rem 0rem',
      a: {
        margin: '0.25rem'
      }
    },
    '.copyright': {
      color: 'white',
      padding: '0.25rem 1rem 0.25rem 0rem',
      margin: '0rem'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['3rem', '3.5rem', '4rem', '4.5rem', '5.5rem'],
    fontWeight: 'normal',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'primary',
    fontStyle: 'italic'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'left',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',
    color: 'secondary',
    border: 'none',
    fontWeight: 'bold',
    fontFamily: 'display',
    textTransform: 'uppercase',
    color: 'black',
    letterSpacing: '1px'
  },
  text: {
    // textAlign: 'center',
    lineHeight: '1.75',
    p: {
      lineHeight: '1.75',
      // textAlign: 'center',
      fontSize: 'inherit',
      color: 'inherit'
    }
  },

  sideBySide1: {
    height: ['', '', '80vh'],
    alignItems: ['center', 'center', 'center', 'center'],
    overflow: 'hidden',
    margin: ' 10vh 0rem',
    '.content': {
      width: ['100%', '', '40%'],
      backgroundColor: 'white',
      zIndex: '1'
    },
    '.lazyload-wrapper': {
      width: ['100%', '', '60%'],
      position: 'relative',
      left: '-10rem',
      zIndex: '0',
      overflow: 'visible',
      img: {
        maxHeight: '70vh',
        width: '120%',
        maxWidth: '120%'
      }
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '3.5rem', '4rem', '4.5rem', '5.5rem'],
      border: 'none',
      padding: '0rem',
      textAlign: 'left',
      position: 'relative',
      left: '-0.5rem',
      marginBottom: '0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'left'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  centerBlock: {
    variant: 'customVariants.sideBySide1',
    '*': {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0rem auto'
    },
    '.title': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      color: 'white',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      color: 'background'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      color: 'white',
      '*': {
        color: 'white'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    padding: ['3rem 0rem 0rem', '', '112px 0rem 0rem'],
    height: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '.slick-slider, .slick-list, .slick-slide, .slick-slide > div, .slick-slide img': {
      height: '70vh !important',
      width: '100%'
    },
    'svg.slick-arrow': {
      backgroundColor: '#cea3526e',
      height: '30px',
      width: '30px',
      color: 'white',
      padding: '0.1rem'
    },
    'svg.slick-prev': {
      left: '0.25rem'
    },
    'svg.slick-next': {
      right: '0.25rem'
    },

    '.hero_content_container': {
      boxSizing: 'border-box',
      position: 'static',
      order: '2',
      width: '90%',
      maxWidth: 'unset',
      transform: 'unset',
      position: 'relative',
      // top: ['-2rem', '-2rem', '-3rem', '-4rem', '-5rem', '-6rem'],
      left: '0rem'
    },

    '.title': {
      variant: 'customVariants.title',
      fontWeight: 'bold',
      fontSize: ['3rem', '4rem', '5rem', '6rem', '7rem', '8rem'],
      fontFamily: 'display3',
      marginBottom: '0rem',
      color: 'text',
      borderBottom: '1px solid'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.75rem', '2rem', '2.5rem', '3rem'],
      fontFamily: 'body',
      color: 'text',
      fontWeight: '200',
      marginBottom: '0rem',
      textAlign: 'right',
      width: '90%',
      textTransform: 'lowercase'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none !important'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageAbout2: {
    variant: 'customVariants.sideBySide1',
    'div.lazyload-wrapper': {
      left: '0rem'
    }
  },

  homepageMenuTitle: {
    background: 'unset',
    paddingTop: '4rem',
    backgroundColor: 'backgroundSecondary',
    '.title': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      color: 'white',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
      margin: '0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      color: 'secondary'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      color: 'text',
      '*': {
        color: 'white'
      }
    }
  },

  homepageBoxes: {
    backgroundColor: 'backgroundSecondary',
    margin: '0rem',
    '.box': {
      position: 'relative',
      minHeight: '40vh',
      transition: 'all ease-in-out 0.8s',
      color: 'white',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '.image': {
        transition: 'all ease-in-out 0.8s',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: ['0.3', '', '', '1']
      },
      ':hover': {
        '.image': {
          opacity: '0'
        }
      },

      '.title': {
        variant: 'customVariants.title',
        textAlign: 'center',
        color: 'white',
        margin: '0rem',
        fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem', '2.75rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        textAlign: 'center',
        color: 'secondary',
        fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.4rem']
      },
      '.text': {
        variant: 'customVariants.text',
        textAlign: 'center',
        color: 'white',
        '*': {
          color: 'white'
        }
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },
  homepageTeam: {
    variant: 'customVariants.sideBySide1'
  },
  homepageContact: {
    variant: 'customVariants.centerBlock'
  },

  homepageQuote1: {
    variant: 'customVariants.centerBlock'
  },
  homepageQuote2: {
    variant: 'customVariants.centerBlock',
    margin: '0rem'
  },

  homepageLocations: {
    backgroundColor: '#e4e2d7',
    margin: '0rem',
    padding: '1.5rem 1rem',
    '.box': {
      width: ['50%', '', '', '25%'],
      padding: ['1rem', '', '', '2rem'],
      '.image': {
        objectFit: 'contain',
        maxHeight: '60px'
      },
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1rem', '1rem', '1rem', '1rem', '1rem'],
        color: 'text',
        border: 'none',
        textAlign: 'center',
        margin: '1.5rem 0rem 0rem'
      }
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 2rem 1rem'],
    margin: ['2rem 0rem 3rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      backgroundColor: '#7f6a5c',
      fontFamily: 'body'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['0px', '', '', 'solid 3px black'],
      borderColor: ['', '', '', 'black'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['#85493d', '', '', 'transparent'],
      color: ['white', '', '', 'black'],
      margin: ['', '', '', '8px']
    },
    '.date': {
      backgroundColor: 'tertiary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },
  homepageTestimonials: {
    padding: ['2rem 1rem', '', '6rem 1rem'],
    maxWidth: 'unset',
    backgroundColor: 'dark',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      color: '#b5ab9c',
      border: 'none',
      textAlign: 'center',
      fontWeight: 'normal',
      margin: '0 auto 1rem'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.subtitle',
      fontSize: '1rem'
    },
    '.menuItemContainer': {
      height: '400px'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      '::after': {
        content: "'★★★★★'",
        margin: '1rem',
        fontSize: '2rem',
        color: 'secondary'
      }
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'white',
      fontSize: ['1.1rem', '', '1.15rem'],
      textAlign: 'center',
      lineHeight: '1.75',
      width: '95%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '1rem auto 2rem',
      maxHeight: '300px',
      overflowY: 'scroll'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    },
    '.slick-slider .slick-arrow': {
      width: '40px',
      height: '40px',
      padding: '0.2rem',
      border: 'solid 1px white',
      color: 'white'
    },
    '.slick-dots li button:before': {
      fontSize: '1.1rem',
      color: 'white'
    },
    '.slick-dots li.slick-active button:before': {
      color: 'primary'
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem', '3.5rem']
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: '#562316',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '1rem',
      width: ['100%', '', '45%', '40%'],
      backgroundColor: '#f1eadf'
    },
    iframe: {
      width: ['100%', '', '55%', '60%']
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      color: 'text',
      width: '100%',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center',
      borderTop: '1px solid black',
      borderBottom: '1px solid black'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Team page  ====
  // ? ========================

  teamMenu: {
    padding: ['0rem', '0rem', '0rem', '0rem'],
    '.box': {
      height: '100%'
    },
    '.textContent': {
      position: 'static',
      transform: 'unset',
      '.title': {
        color: 'text'
      }
    }
  },

  teamPage: {
    backgroundColor: 'white',

    '::before': {
      backgroundColor: 'white',
      content: '""',
      height: '140px'
    },
    '.title': {
      color: 'text'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'cover'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
