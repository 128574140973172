export default {
  text: '#0c0c0c',
  primary: '#f1eadf',
  secondary: '#d68f5e',
  tertiary: '#5B564D',
  background: '#EFEFEF',
  backgroundSecondary: '#393939',
  light: '#FFF',
  dark: '#050707'
}
